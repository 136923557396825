<template>
  <div v-loading="loading">
    <el-tabs type="border-card">
      <el-tab-pane :label="`${$t('actions.edit')} ${$tc('models.company')}`">
        <ui-card
          :heading="`${$t('actions.edit')} ${$tc('models.company')}`"
          class="mb-8"
        >
          <company-form
            v-if="company"
            :company="company"
            @submit="handleSubmit"
            @cancel="handleCancel"
            @update-selected-permissions="evtUpdateSelectedPermissions"
          ></company-form>
        </ui-card>
      </el-tab-pane>

      <el-tab-pane :label="$tc('links.registration_links', 2)">
        <ui-card :heading="$tc('links.registration_links', 2)" class="mb-8">
          <ui-list
            v-if="registrationLinks"
            :disableXScroll="true"
            :headings="[
              $t('fields.name'),
              $t('fields.reference'),
              $t('fields.expires'),
              $t('fields.actions'),
              // ''
            ]"
            :items="registrationLinks"
          >
            <template v-slot:default="props">
              <ui-list-data :text="props.item.name" />
              <ui-list-data :text="props.item.reference" />
              <ui-list-data
                v-if="
                  props.item.expires &&
                  new Date(props.item.expires).getTime() < new Date().getTime()
                "
                :text="new Date(props.item.expires).toLocaleDateString('en-US')"
                style="color: red"
              />
              <ui-list-data
                v-else
                :text="
                  props.item.expires
                    ? new Date(props.item.expires).toLocaleDateString('en-US')
                    : '-'
                "
              />
              <ui-list-data small>
                <ui-link
                  type="danger"
                  uppercase
                  size="small"
                  @click="handleDeleteRegistraionLink(props.item.reference)"
                  >{{ $t("actions.delete") }}</ui-link
                >
                <ui-link
                  class="ml-2"
                  type="success"
                  uppercase
                  size="small"
                  @click="handleEditRegistraionLink(props.item)"
                  >{{ $t("actions.edit") }}</ui-link
                >
              </ui-list-data>
            </template>
          </ui-list>
          <ui-link
            class="ml-2"
            type="success"
            uppercase
            size="small"
            @click="handleNewLink()"
            >{{ $t("links.new") }}</ui-link
          >
        </ui-card>
      </el-tab-pane>
    </el-tabs>

    <ui-modal
      :show="showDeleteModal"
      :text="$t('links.actions.confirm_delete')"
      :info="$t('links.info.delete_link_info')"
      :confirmText="$t('actions.delete')"
      @confirm="deleteRegistrationLink()"
      @cancel="showDeleteModal = false"
    >
    </ui-modal>
  </div>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiList from "@/components/ui/UiList";
import UiLink from "@/components/ui/UiLink";
import UiModal from "@/components/ui/UiModal";
import UiListData from "@/components/ui/UiListData";
import CompanyForm from "../components/CompanyForm";
import {
  getCompany,
  updateCompany,
  deleteCompany,
  getCompanyPermissions,
  putCompanyPermissions,
  getCompanyDepartments,
  getRegistrationLinkByCompanyId,
  deleteRegistrationLinkByReference,
} from "../api";

export default {
  data() {
    return {
      company: null,
      loading: false,
      allPermissions: null,
      companyPermissions: null,
      selectedPermissions: null,
      registrationLinks: null,
      selectedReference: null,
      showDeleteModal: false,
      markedRegistrationLink: null,
    };
  },

  components: {
    UiCard,
    CompanyForm,
    UiListData,
    UiList,
    UiLink,
    UiModal,
  },

  methods: {
    handleNewLink() {
      this.$router.push({
        name: "companies-link",
      });
    },
    handleDeleteRegistraionLink(reference) {
      this.markedRegistrationLink = this.registrationLinks.find(
        (r) => r.reference === reference
      );
      this.showDeleteModal = true;
    },

    async deleteRegistrationLink() {
      deleteRegistrationLinkByReference(this.markedRegistrationLink.reference)
        .then(
          () =>
            (this.registrationLinks = this.registrationLinks.filter(
              (r) => r.reference !== this.markedRegistrationLink.reference
            ))
        )
        .then(() => (this.markedRegistrationLink = null))
        .finally(() => (this.showDeleteModal = false));
    },

    async getCompany() {
      this.loading = true;
      try {
        await getCompany(this.$route.params.id).then(async (company) => {
          try {
            company.permissions = await getCompanyPermissions(company.id);
          } catch (e) {
            company.permissions = [];
          }
          company.departments = await getCompanyDepartments(company.id);

          if (!company.permissions) company.permissions = [];
          this.company = company;
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await updateCompany(this.company);

        // Only update permissions is it's modified
        if (
          this.selectedPermissions != null &&
          this.selectedPermissions != this.companyPermissions
        ) {
          await putCompanyPermissions(
            this.company.id,
            this.selectedPermissions.map((e) => e.id)
          );
        }

        this.$router.push({ name: "companies-index" });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    async deleteCompany() {
      this.loading = true;
      try {
        await deleteCompany(this.company.id);
        this.showDeleteModal = false;
        this.$router.back();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.back();
    },

    evtUpdateSelectedPermissions(selectedPermissions) {
      this.selectedPermissions = selectedPermissions;
    },

    handleEditRegistraionLink(reference) {
      const refId =
        reference instanceof String ? reference : reference.reference;
      this.$router.push({
        name: "companies-link-edit",
        params: {
          reference: refId,
        },
      });
    },
  },
  created() {
    this.getCompany();
  },

  async mounted() {
    let company = await getCompany(this.$route.params.id);
    this.registrationLinks = await getRegistrationLinkByCompanyId(
      this.$route.params.id
    );

    this.$store.commit("changeBreadcrumbs", [
      { name: "models.companies", link: "/app/companies/" },
      { name: company.name, link: "/app/companies/" + company.id + "/edit" },
      { name: "actions.edit", link: "" },
    ]);
  },
};
</script>
